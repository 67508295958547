<template>
	<div v-loading="pageLoading">
		<div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<!-- 级别2 -->
				<div style="background: #fff;padding:0 0 15px;margin-top: 10px;" v-for="(item, index) in ruleForm.modeConfig" :key="index">
					<div class = "flexRow" style="background: #DCDFE6;padding: 10px 15px;margin-bottom: 15px;">
						<div>
							级别{{ numberFormat[index] }}
						</div>
						<div>
							<buttonPermissions :datas="'modeConfigDel'" v-if="item.DistributLevel==Math.max(...ruleForm.modeConfig.map(v=>v.DistributLevel))">
								<el-button type="text" style="color:#f56c6c" @click="del(item,index)">删除</el-button>
							</buttonPermissions>
						</div>
					</div>
					<el-form-item label="级别名称" :rules="rules.DistributRoleName" :prop="'modeConfig.'+index+'.DistributRoleName'">
						<el-input v-model="item.DistributRoleName" placeholder="最多输入10个字" size="small" style="width: 400px;"></el-input>
					</el-form-item>
					<el-form-item label="权益说明" :rules="rules.Instructions" :prop="'modeConfig.'+index+'.Instructions'">
						<div style="width: 400px;">
							<el-input type="textarea" v-model="item.Instructions" rows="4" placeholder="请输入权益说明，最多500字"></el-input>
						</div>
					</el-form-item>
					<el-form-item required label="升级条件" v-if="index>0">
						<div style="display: flex;align-items: center;">
							<div style="width: 80px;text-align: right;margin-right: 10px;">销售额达到</div>
							<el-input v-model="item.UpToCurrentRolePerformanceMoney" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"
							 style="margin-right: 10px;width: 300px;" size="small"></el-input>
							元
						</div>
						<div style="display: flex;align-items: center;">
							<div style="width: 80px;text-align: right;margin-right: 10px;">或 直接推荐</div>
							<el-input v-model="item.UpToCurrentDirectRecommendCount" style="margin-right: 10px;width: 300px;" size="small"
							 onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[0-9]\d*/)||'';}).call(this);"></el-input>
							人
						</div>
						<div style="display: flex;align-items: center;">
							<div style="width: 80px;text-align: right;margin-right: 10px;">间接推荐</div>
							<el-input v-model="item.UpToCurrentIndirectRecommendCount" style="margin-right: 10px;width: 300px;" size="small"
							 onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[0-9]\d*/)||'';}).call(this);"></el-input>
							人
						</div>
					</el-form-item>
				</div>

				<el-button v-if="ruleForm.modeConfig.length!=6" style="width: 200px;margin: 20px 0 100px;" type="primary" @click="addLevel">添加晋升级别</el-button>
				<!--  -->
				<div style="background: #fff;padding: 20px;margin-bottom: 20px;display: flex;justify-content: center;margin-top: 10px;">
					<!-- <el-button style="width: 180px;" @click="cancelBtn('ruleForm')" v-if="showNewMode && configData.length < 3">取消</el-button> -->
					<el-button style="width: 180px;margin-left: 50px;" type="primary" @click="saveBtn('ruleForm')" :loading="loading">保存</el-button>
				</div>
			</el-form>
		</div>

		<!-- 删除限制弹框 -->
		<el-dialog :visible.sync="limitVisble" width="550px" title="">
			<div style="line-height: 30px;">晋升级别添加成功后，不可删除。请确认是否继续添加晋升级别</div>
			<span slot="footer" class="dialog-footer">
				<el-button style="width: 120px;" @click="handleLimitEvent(0)">取 消</el-button>
				<el-button style="width: 120px;margin-left: 30px;" type="primary" @click="handleLimitEvent(1)" v-loading="sureBtning">确
					定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import buttonPermissions from '@/components/buttonPermissions';
	import {
		distributorconfig,
		savedistributorconfig,
		malldeletedistributorrole
	} from '@/api/wyUsedInterface.js';
	export default {
		components:{
			buttonPermissions
		},
		data() {
			return {
				pageLoading:false,
				loading: false,
				sureBtning: false,
				ruleForm: {
					modeConfig: [{
						Id: 0,
						DistributRoleName: '',
						DistributLevel: 1,
						UpToCurrentRolePerformanceMoney: '',
						UpToCurrentDirectRecommendCount: '',
						UpToCurrentIndirectRecommendCount: '',
						Instructions: ''
					}]
				},
				rules: {
					DistributRoleName: [{
						required: true,
						message: '请填写级别名称',
						trigger: 'blur'
					}, {
						min: 1,
						max: 10,
						message: '最多输入10个字',
						trigger: 'blur'
					}],
					Instructions: [{
							required: true,
							message: '请输入权益说明，最多500字',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 500,
							message: '最多输入500个字',
							trigger: 'blur'
						}
					],
				},
				showNewMode: false,
				configData: [],
				limitVisble: false,
				numberFormat: ['一', '二', '三', '四', '五', '六'],
				isSubmits: true,
				modeData:[],
			};
		},
		beforeMount() {
			this.getDetail();
		},
		methods: {
			del(item,i){
				this.$confirm('删除后，不可恢复，是否确认继续删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
					if(this.ruleForm.modeConfig.length<=1){
						this.$confirm('删除失败，至少保留1个推手级别。若你不希望继续招募推手，可前往商品列表页，下架礼包授权商品即可。', '提示', {
							showConfirmButton:false,
							cancelButtonText: '关闭',
							type: 'warning'
						}).then(()=>{}).catch(()=>{})
					}else if(!item.Id){
						this.ruleForm.modeConfig.splice(i,1)
					}else{
						this.confirmdel(item)
					}
				}).catch(()=>{})
			},
			async confirmdel(item){
				this.pageLoading = true
				try{
					let msg = await item
					let data = {
						Id:msg.Id,
						noError:true
					}
					let res = await malldeletedistributorrole(data)
					if(res.IsSuccess){
						this.$message.success('操作成功')
						this.getDetail();
					}else{
						if(res.ErrorCode==2000013){
							this.$confirm('删除失败，该级别下存在已授权通过的推手，请先前往「 推手查询」中修改对应推手级别。', '提示', {
								confirmButtonText: '前往修改',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(()=>{
								this.$router.push({
									name:'inquiry',
									params:{
										Id:msg.Id
									}
								})
							}).catch(()=>{})
						}
					}
					console.log(res)
				}finally{
					this.pageLoading = false
				}
			},
			async getDetail() {
				let data = {};
				let res = await distributorconfig(data);
				if (res.Result.length > 0) {
					this.ruleForm.modeConfig = res.Result;
					this.modeData = JSON.parse(JSON.stringify(res.Result))
				}
			},

			async saveData() {
				this.pageLoading = true
				try {
					this.isSubmits = true

					this.ruleForm.modeConfig.map(record => {
						var recordvalue = record.UpToCurrentRolePerformanceMoney;
						var result = (recordvalue.toString()).indexOf(".");
						if (result != -1) {
							var newRolePerformanceMoney = (parseInt(recordvalue * 100) / 100).toFixed(2);
							record.UpToCurrentRolePerformanceMoney = newRolePerformanceMoney
						}
						return record
					})

					if (this.ruleForm.modeConfig.length > 1) {
						this.ruleForm.modeConfig.some((item, index) => {
							if (index > 0) {
								if (item.UpToCurrentRolePerformanceMoney.length == 0 && item.UpToCurrentDirectRecommendCount.length == 0 &&
									item.UpToCurrentIndirectRecommendCount.length == 0) {
									console.log(1)
									this.$message({
										showClose: true,
										type: 'warning',
										message: '级别' + this.numberFormat[index] + '至少配置1个升级条件'
									});
									this.limitVisble = false;
									this.isSubmits = false
									return true;
								} else {
									if (index > 0 && item.UpToCurrentRolePerformanceMoney == 0 && (item.UpToCurrentDirectRecommendCount == 0) &&
										(item.UpToCurrentIndirectRecommendCount == 0)) {
										console.log(23)
										this.$message({
											showClose: true,
											type: 'warning',
											message: '级别' + this.numberFormat[index] + '升级条件不能同时为0'
										});
										this.limitVisble = false;
										this.isSubmits = false
										return true;
									}
								}

								if (item.UpToCurrentRolePerformanceMoney > 99999999) {
									this.$message({
										showClose: true,
										type: 'warning',
										message: '级别' + this.numberFormat[index] + '销售金额最大为99999999'
									});
									this.isSubmits = false
									return true;
								}
							}
						})
					}
					if (this.isSubmits) {
						let data = {
							Configs: this.ruleForm.modeConfig
						};
						let res = await savedistributorconfig(data)
						if (res.IsSuccess) {
							this.$message({
								showClose: true,
								type: 'success',
								message: res.Message
							})
							this.getDetail()
						}
					}

				} catch (e) {
					//TODO handle the exception
				} finally {
					this.pageLoading = false

				}

				this.limitVisble = false;
			},

			saveBtn(formName) {
				// console.log(this.ruleForm.modeConfig)
				this.loading = true
				if (this.showNewMode) {
					this.$refs['ruleForm'].validate(valid => {
						if (valid) {
							// this.limitVisble = true;
							this.handleLimitEvent(1)
						} else {
							this.limitVisble = false;
							// console.log('error submit!!');
							return false;
						}
					});
				} else {

					this.$refs['ruleForm'].validate(valid => {
						if (valid) {
							this.saveData();
						} else {
							// console.log('error submit!!');
							return false;
						}
					});
				}

				setTimeout(() => {
					this.loading = false
				}, 500)
			},
			addLevel() {
				let obj = {
					Id: 0,
					DistributRoleName: '',
					DistributLevel: this.ruleForm.modeConfig.length + 1,
					UpToCurrentRolePerformanceMoney: '',
					UpToCurrentDirectRecommendCount: '',
					UpToCurrentIndirectRecommendCount: '',
					Instructions: ''
				}
				this.ruleForm.modeConfig.push(obj)
				this.showNewMode = true;
			},
			async handleLimitEvent(type) {
				try {
					this.sureBtning = true
					if (type == 0) {
						this.limitVisble = false;
						// this.$refs['ruleForm'].resetFields();
						// this.showNewMode = false;
					} else {
						this.saveData();
					}
				} catch (e) {
					//TODO handle the exception
				} finally {
					setTimeout(() => {
						this.sureBtning = false
					}, 500)
				}

			}
		}
	};
</script>

<style lang="less" scoped>
.flexRow{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
</style>
